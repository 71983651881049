<script setup>
import Layout from '@/Layouts/Guest.vue';
import {Head, Link, router, useForm, usePage} from '@inertiajs/vue3';
import {ref, computed, onMounted} from 'vue';
import {arrayBufferToBase64, recursiveBase64StrToArrayBuffer} from '@/passkeyFns';

const page = usePage();
const subdomain = computed(() => page.props.subdomain);
const message = computed(() => {
    return page.props.flash.message
});

const props = defineProps({
    canResetPassword: Boolean,
    status: String
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    showKeepassAlert.value = false;
    form.clearErrors();
    form.post('/login', {
        onFinish: () => form.reset('password')
    });
};

const showGetPasskey = ref(false);
const getPasskeyLoading = ref(false);
const showKeepassAlert = ref(false);

onMounted(async () => {
    if (!window.PublicKeyCredential ||
        !PublicKeyCredential.isConditionalMediationAvailable)
        return false;
    const isCMA = await PublicKeyCredential.isConditionalMediationAvailable();
    if (!!isCMA)
        showGetPasskey.value = true;
});

async function getPasskey() {
    showKeepassAlert.value = false;
    if (!showGetPasskey.value) return;

    getPasskeyLoading.value = true;

    let response = await axios
        .get('/passkey/get');
    let getArgs = response?.data;
    if (!getArgs) {
        getPasskeyLoading.value = false;
        return;
    }

    recursiveBase64StrToArrayBuffer(getArgs);

    const credential = await navigator.credentials.get(getArgs)
        .catch(() => {
        });
    if (!credential) {
        getPasskeyLoading.value = false;
        return;
    }

    const authenticatorAttestationResponse = {
        id: credential.rawId ? arrayBufferToBase64(credential.rawId) : null,
        clientDataJSON: credential.response.clientDataJSON ? arrayBufferToBase64(credential.response.clientDataJSON) : null,
        authenticatorData: credential.response.authenticatorData ? arrayBufferToBase64(credential.response.authenticatorData) : null,
        signature: credential.response.signature ? arrayBufferToBase64(credential.response.signature) : null,
        userHandle: credential.response.userHandle ? arrayBufferToBase64(credential.response.userHandle) : null
    };

    router.post(
        '/passkey/get',
        authenticatorAttestationResponse,
        {
            onError: () => {
                showKeepassAlert.value = true;
            },
            onFinish: () => {
                getPasskeyLoading.value = false;
            }
        }
    );
}
</script>

<template>
    <Layout>
        <Head title="Log in"/>

        <v-sheet
            width="300"
            class="mx-auto text-center position-relative"
        >
            <div
                class="d-flex justify-end position-absolute w-100"
                style="top:-56px"
            >
                <v-icon
                    icon="mdi-beta"
                    size="x-large"
                    end
                    :color="subdomain"
                    class="mx-3 my-3"
                ></v-icon>
            </div>
            <v-text-field
                hide-details
                class="mb-4"
                v-model="form.email"
                label="Email"
                @keyup.enter="submit"
            ></v-text-field>
            <v-text-field
                hide-details
                class="mb-4"
                v-model="form.password"
                label="Пароль"
                type="password"
                @keyup.enter="submit"
            ></v-text-field>
            <v-checkbox
                v-if="1"
                hide-details
                v-model="form.remember"
                label="Запомнить меня"
            ></v-checkbox>
            <Link
                class="d-block mb-4 text-left px-4"
                v-if="canResetPassword && 0"
                :href="route('password.request')"
            >
                Забыли пароль?
            </Link>
            <v-alert
                v-if="Object.keys(form.errors).length"
                color="error"
                class="mb-4"
            >
                <div v-for="error in form.errors">{{ error }}</div>
            </v-alert>

            <v-alert
                v-if="showKeepassAlert"
                class="mb-4"
            >
                Ключ для входа не найден. Войдите по паролю, чтобы создать ключ.
            </v-alert>

            <div class="btns-container">
                <v-alert
                    v-if="message"
                    class="mb-4"
                    :color="'secondary'"
                >
                    {{ message }}
                </v-alert>
                <v-btn
                    class="my-2"
                    @click="submit"
                    :loading="form.processing"
                    :color="subdomain"
                >
                    Войти
                </v-btn>

                <v-btn
                    v-if="showGetPasskey"
                    :color="subdomain"
                    @click="getPasskey"
                    :loading="getPasskeyLoading"
                    icon="mdi-fingerprint"
                    density="comfortable"
                    class="passkey-btn my-2"
                ></v-btn>
            </div>
        </v-sheet>
    </Layout>
</template>

<style>
.btns-container {
    position: relative;
}

.v-btn.passkey-btn {
    position: absolute;
    right: 18px;
}
</style>
